import React, { useCallback, useMemo, useState } from 'react'
import TopFloater from '../guest/TopFloater'
import { useAppSelector } from '../../hooks/appHook'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useGetAccountQuery } from '../../store/api/account.api'
import StepsIndicator from './components/StepsIndicator/StepsIndicator'
import CardHeader from './components/CardHeader/CardHeader'
import StepsForm from './components/StepsForm/StepsForm'
import '../../layouts/guestLayout/guestLayout.less'
import './onboarding.less'

const steps = [
  {
    title: 'What is your company name?',
  },
  {
    title: 'What is your company size?',
  },
  {
    title: 'What is your use case?',
  },
]

const stepsLength = steps.map((_el, i) => i + 1)

const Onboarding = () => {
  /** Storage */
  const { user: {name, id} } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const { error} = useGetAccountQuery(undefined, { skip: !id })

  /** State */
  const [currentStep, setCurrentStep] = useState(0)

  /** Response message handlers */
  useErrorMessage('Something went wrong', error)

  const stepsTitle = useMemo(() => {
    return steps[currentStep]?.title
  }, [currentStep])

  const movePrevStep = useCallback(() => {
    setCurrentStep((prev) => prev - 1)
  }, []);

  const moveNextStep = useCallback(() => {
    setCurrentStep((prev) => prev + 1)
  }, []);

  return (
    <>
      <TopFloater isSignup />
      <div className="poll-box">
        <div className="poll-wrapper">
          <CardHeader userName={name}/>
          <StepsIndicator currentStep={currentStep} items={stepsLength} />
          <StepsForm
            stepTitle={stepsTitle}
            currentStep={currentStep}
            moveNextStep={moveNextStep}
            movePrevStep={movePrevStep}
          />
        </div>
      </div>
    </>
  )
}

export default Onboarding
