import React, {memo} from 'react'
import { Collapse, Form, InputNumber } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'

const OtherSettings = () => {
  return (
    <>
      <h2>Other Settings</h2>
      <Collapse className="form-collapse-container">
        <CollapsePanel header="Distance Threshold. Results quantity" key="1" className="form-collapse-panel">
          <>
            <Form.Item
              label="Distance Threshold"
              name="distanceThreshold"
              tooltip={{
                title:
                  'How similar data should be used in searhc results. The bigger number the less similar data will be fetched. Should be a number between 0 and 10',
                icon: <InfoCircleOutlined />,
              }}
              style={{ marginBottom: '8px' }}
            >
              <InputNumber style={{ width: '100%' }} min={0} max={10} />
            </Form.Item>
            <Form.Item
              label="Results quantity"
              name="resultsQuantity"
              tooltip={{
                title:
                  'How many results should we use when search for data. Should be a number between 0 and 40. By defautl - 3.',
                icon: <InfoCircleOutlined />,
              }}
              style={{ marginBottom: '8px' }}
            >
              <InputNumber style={{ width: '100%' }} min={0} max={40} />
            </Form.Item>
          </>
        </CollapsePanel>
      </Collapse></>
  )
}

export default memo(OtherSettings)
