import React from 'react';
import TransparentButton from '../../../../components/UI/TransparentButton/TransparentButton';
import { useAppSelector } from '../../../../hooks/appHook';

const TestChatButton = () => {
  const { account } = useAppSelector((state) => state.account)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const handleOnPressTestChat = () => {
    if (account?.id && currentStorageId) {
      window.open(`${location.origin}/c/${account.id}?storageId=${currentStorageId}`, '_blank')
    }
  }
  return <TransparentButton onClick={handleOnPressTestChat} title="Test Chat" style={{width: 150}}/>
}

export default TestChatButton;
