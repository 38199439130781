import React, {
  useEffect,
  forwardRef,
  LegacyRef,
  memo,
  useState,
  useCallback,
  KeyboardEventHandler,
} from 'react'
import { Button, Form, Input, notification } from 'antd'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Message } from '../../types/storage.type'
import { Conversation } from '../../types/conversation.type'
import ReactMarkdownContent from '../../routes/chat/components/ReactMarkdownContent/ReactMarkdownContent'
import AddImageByLinkModal from '../UI/Modals/AddImageByLinkModal'
import AddImagesPopover from '../../routes/chat/components/AddImagesPopover/AddImagesPopover'
import SelectedImages from '../../routes/chat/components/SelectedImages/SelectedImages'
import './chat.less'

export interface ConversationMessageItem {
  message?: string;
  linksImages?: string[]
  fileImages?: File[]
}

interface Props {
  onSend: (values: ConversationMessageItem) => void
  data: Conversation | { id: string | undefined; messages: Array<Message> }
  isFetching: boolean
  placeholder: string
  isEditable: boolean
  successMessageSend?: boolean;
  withFileAttachment?: boolean
}

const Chat = forwardRef(function Chat(
  { onSend, data, isFetching, placeholder, isEditable, successMessageSend, withFileAttachment }: Props,
  ref: LegacyRef<HTMLDivElement>,
) {
  const [form] = Form.useForm()

  /** State */
  const [uploadedImages, setUploadedImages] = useState<Array<File | string>>([])

  const handleFileUpload = (files: File[]) => {
    const validFiles = files.filter(file =>
      ['image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(file.type) && file.size <= 20 * 1024 * 1024
    )
    if (uploadedImages.length + validFiles.length <= 5) {
      setUploadedImages(prev => [...prev, ...validFiles])
    } else {
      notification.error({
        message: 'Maximum number of added images cannot exceed 5',
      })
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleFileUpload(acceptedFiles);
  }, [handleFileUpload, uploadedImages]);

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    notification.error({
      message: rejectedFiles[0].errors[0].message,
    })
  };

  /** Use custom hooks */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/gif': [],
    },
    maxSize: 20 * 1024 * 1024,
    noClick: true
  })

  const handleImageLinkAdd = useCallback((imageLink: string) => {
    /** Validate image link before adding */
    setUploadedImages(prev => [...prev, imageLink]) // Placeholder file object for preview
  }, [])

  const removeImage = useCallback((index: number) => {
    setUploadedImages(prev => prev.filter((_, i) => i !== index))
  }, [])

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      form.submit()
    }
  }

  const onSubmit = (values: { newMessage: string }) => {
    const linksImages = uploadedImages.filter(image => typeof image === 'string' && image) as string[];
    const fileImages = uploadedImages.filter(image => typeof image !== 'string' && image) as File[];

    if (![...linksImages, ...fileImages].length && !values.newMessage.trim()) {
      return
    }

    const request: ConversationMessageItem = {
      message: values.newMessage,
      linksImages,
      fileImages
    }

    onSend(request)
  }

  useEffect(() => {
    if (!isFetching) {
      form.resetFields()
    }
  }, [isFetching])

  useEffect(() => {
    if (successMessageSend && !isFetching) {
      setUploadedImages([])
      form.resetFields()
    }
  }, [successMessageSend, isFetching])

  return (
    <div
      className="space-chat-container">
      <div id="chat-container" ref={ref} className="chat-messages-list-container">
        <ul>
          {data?.messages?.map((message: Message, index) => (
            <ReactMarkdownContent message={message} key={index} />
          ))}
        </ul>
      </div>
      {isEditable && (
        <Form form={form} layout={'vertical'} onFinish={onSubmit} disabled={isFetching}>
          <div className="form-item-container" {...getRootProps()}>
            {uploadedImages?.length > 0 ? <SelectedImages
              images={uploadedImages}
              removeImage={removeImage}
            /> : null}
              <input {...getInputProps()} />
              <Form.Item name="newMessage">
                <Input.TextArea
                  id="form-input"
                  autoFocus={true}
                  placeholder={placeholder}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  className="form-item-message-textarea"
                  onPressEnter={handleKeyPress}
                />
              </Form.Item>
            {withFileAttachment && <AddImagesPopover
              isFullSelectedImages={uploadedImages.length === 5}
              onDrop={onDrop}
              disabled={isFetching}
            />}
          </div>
          <Button id="form-button" block type="primary" htmlType="submit" loading={isFetching} disabled={isFetching}>
            Send
          </Button>
        </Form>
      )}
      {withFileAttachment && <AddImageByLinkModal handleImageLinkAdd={handleImageLinkAdd} />}
    </div>
  )
})

export default memo(Chat)
