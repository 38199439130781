import React, {memo} from "react";
import {Button, ButtonProps} from "antd";
import "./appPrimaryButton.less";

const AppPrimaryButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      id="app-primary-button"
      {...props}
    >
      {children}
    </Button>
  );
};

export default memo(AppPrimaryButton);
