import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Layout, Spin } from 'antd'
import Sidebar from './components/Sidebar/Sidebar'
import Header from './components/Header/Header'
import { useAppSelector } from '../../hooks/appHook'
import { AccountStatusEnum } from '../../types/account.type'
import { useGetAccountQuery } from '../../store/api/account.api'
import InviteToTeamModal from '../../components/UI/Modals/InviteToTeamModal'
import {
  ONBOARDING_ROUTE,
  SIGN_IN_ROUTE,
} from '../../config/consts'
import './mainLayout.less'

const {Content } = Layout

const MainLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  /** Storage */
  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const { data, isLoading } = useGetAccountQuery(undefined, { skip: !user.id })

  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  useEffect(() => {
    if (isEmpty(data)) {
      return
    }

    const isOnboarding = account.status === AccountStatusEnum.ONBOARD

    if (isOnboarding && location.pathname !== ONBOARDING_ROUTE) {
      return navigate(ONBOARDING_ROUTE)
    }
  }, [data, account])

  if (!user?.id) {
    return <Navigate to={SIGN_IN_ROUTE} state={{ from: location }} />
  }

  return (
    <div>
      <div className="bg-shape" />
      <div className="bg-shape" />
      <div className="bg-shape" />
      <div className="bg-shape" />
      <Spin spinning={isLoading}>
        <Layout id="ioni-main-layout">
          <InviteToTeamModal />
          <Sidebar userRole={user.role} />
          <Layout>
            <Header user={user} />
            <Content className="content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default MainLayout
