export const removeAttributes = (str: string) => {
  const regex = /data-agent-id="[^"]*"|data-host="[^"]*"/g;
  return str.replace(regex, '');
}

export const addDataAgentIdAttribute = (str: string, agentId: string) => {
  if (agentId) {
    return str.replace(/(src="[^"]*")/, `data-agent-id="${agentId}"`);
  } else {
    return str;
  }
}
