import React, {memo} from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SiderProps } from 'antd/es/layout';
import Logo from '../../../../components/UI/Logos/Logo'
import { adminItems, sliderMenuItems } from '../../navigationItems'
import { RolesEnum } from '../../../../config/rolesEnum'
import './sidebar.less'

const { Sider } = Layout;

interface SidebarProps extends SiderProps {
  userRole: RolesEnum | null;
}

const Sidebar = ({
  userRole,
  ...siderProps
}: SidebarProps) => {
  const location = useLocation();

  const menuItems = userRole === RolesEnum.ADMIN
    ? [...sliderMenuItems, ...adminItems]
    : sliderMenuItems;

  return (
    <Sider
      width={274}
      id="ioni-layout-sider"
      breakpoint="lg"
      collapsedWidth="0"
      className="ioni-layout-sider"
      {...siderProps}
    >
      {/** Left Sidebar logo */}
      <Link to="/" className="logo">
        <Logo name="migratorLogo" />
      </Link>

      {/** Left Sidebar navigation */}
      <Menu
        mode="inline"
        items={menuItems}
        selectedKeys={[location.pathname]}
      />
    </Sider>
  );
};

export default memo(Sidebar);
