import React, { useEffect, useState } from 'react'
import { Checkbox, Dropdown, Form, Input, MenuProps } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Icon from '../../../components/UI/Icons/Icons'
import TransparentButton from '../../../components/UI/TransparentButton/TransparentButton'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useAppSelector } from '../../../hooks/appHook'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import Loading from '../../../components/UI/Loading'
import { requiredValidator } from '../../../helpers/validators'
import { useEditAgentMutation, useLazyGetAgentByIdQuery } from '../../../store/api/agent.api'

interface IAgentTargetRequest {
  target: {
    type: string
    notifyEmail?: string
    customText?: string
  }
}

const CallToActionTabContent = () => {
  const [form] = Form.useForm()

  /** Storage */
  const { account } = useAppSelector((state) => state.account)
  const { currentAgentId, agent } = useAppSelector((state) => state.agents)

  /** Storage actions */
  const [getAgent, getAgentQueryResult] = useLazyGetAgentByIdQuery()
  const [updateAgent, updateAgentMutationResult] = useEditAgentMutation()

  /** State */
  const [checkedValue, setCheckedValue] = useState('in-app')
  const [isTemplateEdited, setIsTemplateEdited] = useState(false)

  /** Response message handlers */
  useErrorMessage('Server error!', updateAgentMutationResult.error)
  useSuccessMessage('Changes saved successfully!', updateAgentMutationResult.isSuccess)

  const onChooseTemplate = (text: string) => {
    form.setFieldValue('template', text)
    form.validateFields()
  }

  const handleCheckboxChange = (eventcheckedValue: CheckboxChangeEvent) => {
    if (eventcheckedValue.target.value === 'custom') {
      setIsTemplateEdited(false)
    }

    setCheckedValue(eventcheckedValue.target.value)
    form.validateFields()
  }

  const onAccountUpdate = async (values: { template: string; notifyEmail?: string }) => {
    const request: IAgentTargetRequest = {
      target: {
        type: ['in-app', 'notify'].includes(checkedValue) ? checkedValue : 'custom',
        customText: values.template || '',
      },
    }
    if (values.notifyEmail) {
      request.target.notifyEmail = values.notifyEmail
    }
    updateAgent({
      id: currentAgentId,
      body: request,
    })
    setIsTemplateEdited(false)
  }

  useEffect(() => {
    if (currentAgentId) {
      getAgent({ id: currentAgentId })
    }
  }, [currentAgentId])

  useEffect(() => {
    if (agent?.target) {
      setCheckedValue(['in-app', 'notify'].includes(agent.target.type) ? agent.target.type : 'custom')
      form.setFieldsValue({
        notifyEmail: agent.target.notifyEmail,
        template: agent.target.customText,
      })
    }
  }, [agent])

  const items: MenuProps['items'] = [
    {
      label: (
        <p
          style={{ width: '100%' }}
          onClick={() =>
            onChooseTemplate(
              'Ask customer to book a call with our CEO by the following link: https://calendly.com/our-ceo',
            )
          }
        >
          Ask customer to book a call with our CEO by the following link: https://calendly.com/our-ceo
        </p>
      ),
      key: 'calendly',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <p
          style={{ width: '100%' }}
          onClick={() =>
            onChooseTemplate(
              'Ask customer to talk to us in Whatsapp or any other messenger by visiting link: https://wa.me/34000003200.',
            )
          }
        >
          Ask customer to talk to us in Whatsapp or any other messenger by visiting link: https://wa.me/34000003200.
        </p>
      ),
      key: 'linkedin',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <p
          style={{ width: '100%' }}
          onClick={() => onChooseTemplate('Ask customer to provide his phone number so we may get in touch shortly.')}
        >
          Ask customer to provide his phone number so we may get in touch shortly.
        </p>
      ),
      key: 'phone',
    },
  ]

  if (getAgentQueryResult.isLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    )
  }

  return (
    <div className="call-to-action-tab">
      <h2>Call to Action</h2>
      <p>
        Choose what chatbot will do in case customer couldn&apos;t find required information or would like to talk to a
        human.
      </p>
      <Form form={form} onFinish={onAccountUpdate} layout="vertical">
        <Checkbox.Group value={[checkedValue]} className="checkbox-group-container">
          <div className="checkbox-group-item">
            <h3>Chat in Ioni</h3>
            <div>
              <p>
                Collect “Name” and “Email” and use Conversations section to reply to customers via email. When customer
                replies to email, it will update related conversations. Such conversations will be marked as new.
              </p>
              <Checkbox value="in-app" onChange={handleCheckboxChange} />
            </div>
          </div>
          <div className="checkbox-group-item">
            <h3>Notify agent</h3>
            <div>
              <p>
                Collect “Name” and “Email” from the customer and send email with conversation details to email below.
                Further communication will be handled via specified email.
              </p>
              <Checkbox value="notify" onChange={handleCheckboxChange} />
            </div>

            {checkedValue === 'notify' ? (
              <Form.Item
                label="Notify email"
                name="notifyEmail"
                rules={[
                  { required: true, validator: requiredValidator('Notify email is required!') },
                  { type: 'email', message: 'Please input valid Email!' },
                ]}
                style={{ marginTop: '12px', marginBottom: 0, width: '100%' }}
              >
                <Input />
              </Form.Item>
            ) : null}
          </div>
          <div className="checkbox-group-item">
            <h3>Custom CTA</h3>
            <div>
              <p>
                Setup custom instructions. You can instruct chatbot to provide links or any other information. Please
                see sample below.
              </p>
              <Checkbox value="custom" onChange={handleCheckboxChange} />
            </div>
          </div>
        </Checkbox.Group>

        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement={'topLeft'}
          overlayStyle={{ width: 400, paddingLeft: 100 }}
        >
          <div className="template-menu-title">
            <h3>Custom CTA Samples</h3>
            <Icon name="arrowDown" />
          </div>
        </Dropdown>
        <Form.Item
          name="template"
          initialValue={
            account.botSettings?.customTarget && !['in-app', 'notify'].includes(account.botSettings.customTarget)
              ? account.botSettings?.customTarget
              : ''
          }
          rules={[
            checkedValue === 'custom' && isTemplateEdited
              ? { required: true, validator: requiredValidator('Please input the Template!') }
              : {},
          ]}
        >
          <Input.TextArea
            className="template-textarea"
            autoSize={{ minRows: 4, maxRows: 6 }}
            disabled={checkedValue !== 'custom'}
            onChange={() => setIsTemplateEdited(true)}
          ></Input.TextArea>
        </Form.Item>
        <TransparentButton
          onClick={() => setIsTemplateEdited(true)}
          className="transparent-button-large"
          title="Save changes"
          htmlType="submit"
        />
      </Form>
    </div>
  )
}

export default CallToActionTabContent
