import React, { useMemo } from 'react'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/appHook'
import TransparentButton from '../../../components/UI/TransparentButton/TransparentButton'
import { addDataAgentIdAttribute } from '../../../helpers/strings'
import { CHAT_URL, WIDGET_URL } from '../../../config/consts'

const IntegrationTabContent = () => {
  /** Storage */
  const { user } = useAppSelector((state) => state.profile)
  const { currentAgentId } = useAppSelector((state) => state.agents)
  const { account } = useAppSelector((state) => state.account)
  const { accountId } = user

  const copyToClipboard = () => {
    const contentDiv = document.getElementById('widget-text')
    const textToCopy = contentDiv?.innerText

    const tempInput = document.createElement('textarea')
    tempInput.value = textToCopy || ''
    document.body.appendChild(tempInput)

    tempInput.select()
    document.execCommand('copy')

    document.body.removeChild(tempInput)

    message.success('Text copied to clipboard!')
  }

  const scriptExample = useMemo(() => {
    let inputString =
      `<script 
         id="ioni" 
         data-account-id="${accountId}"
         src="https://${WIDGET_URL}/widget.js"/>
      `;

    if (currentAgentId && currentAgentId !== account?.defaultAgentId) {
      inputString = addDataAgentIdAttribute(inputString, currentAgentId)
    }

    return inputString
  }, [currentAgentId, accountId, account])

  return (
    <div>
      <h2>Integration</h2>
      <p>
        Feel free to copy widget code and paste it into your website. You may also{' '}
        <Link to={`https://${CHAT_URL}/c/${accountId}?agentId=${currentAgentId}`} target="_blank">
          Test Chat
        </Link>{' '}
        to check how ioni replies to the inquiries before.
      </p>
      <div id="widget-content-container">
        <h3>{'</> Widget code'}</h3>
        <p id="widget-text">
          {`${scriptExample}`}
        </p>
      </div>
      <TransparentButton className="transparent-button-large" title="Copy" onClick={copyToClipboard} />
    </div>
  )
}

export default IntegrationTabContent
