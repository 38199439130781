import React, {FC, memo} from "react";
import {Input, InputProps} from "antd";
import "./appAuthInput.less";

const AppAuthInput: FC<InputProps> = ({children, ...props}) => {
  return (
    <Input
      className="app-auth-input-wrapper"
      {...props}
    >
      {children}
    </Input>
  );
};

export default memo(AppAuthInput);
