import React, {memo} from 'react';
import Icon from '../../../../components/UI/Icons/Icons';

interface IProps<T> {
  option: T;
  isSelected: boolean;
  handleClick: (el: T) => void
}

const SelectButton = <T,>({isSelected, handleClick, option}: IProps<T>) => {
  return (
    <button
      type="button"
      className={`poll-cxm-card ${isSelected && 'poll-cxm-card-selected'}`}
      onClick={() => handleClick(option)}
    >
      <>
        {option}
        {isSelected && <Icon name="checkWhite" className="poll-cxm-card-selected-icon" />}
      </>
    </button>
  )
}

export default memo(SelectButton) as typeof SelectButton;
