import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../components/UI/Icons/Icons'
import { CONVERSATIONS_ROUTE, KNOWLEDGE_BASE_ROUTE } from '../../config/consts'

export const sliderMenuItems = [
  {
    label: <Link to="storage">Knowledge Base</Link>,
    key: '/storage',
    icon: <Icon name="addData" width={23} height={23} />,
    path: KNOWLEDGE_BASE_ROUTE,
    title: 'Knowledge Base',
  },
  {
    label: <Link to="conversations">Conversations</Link>,
    key: '/conversations',
    icon: <Icon name="conversations" />,
    path: CONVERSATIONS_ROUTE,
    title: 'See conversations',
  },
]

export const adminItems = [
  // {
  //   label: <Link to="admin/accounts">Accounts</Link>,
  //   key: 'admin/accounts',
  //   icon: <BulbOutlined />,
  //   path: ACCOUNTS_ROUTE,
  //   title: 'Accounts',
  // },
]
