import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompanyCaseOption, CompanySizeOption, IOnboarding } from '../../types/onboarding.type'

export const companySizeOptions: CompanySizeOption[] = ['1', '2-10', '11-50', '51-200', '201-1000', '1000+']
export const companyCaseOptions: CompanyCaseOption[] =
  ['Education & Training' , 'Customer Service' , 'HR & Recruiting' , 'eCommerce' , 'Sales' , 'Other']


export const initialState: IOnboarding = {
  companyName: '',
  companySize: '' as CompanySizeOption,
  useCase: '' as CompanyCaseOption
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload
    },
    setCompanySize: (state, action: PayloadAction<CompanySizeOption>) => {
      state.companySize = action.payload
    },
    setCompanyCase: (state, action: PayloadAction<CompanyCaseOption>) => {
      state.useCase = action.payload
    },
    resetState: () => initialState,
  },
})

export const onboardingActionCreators = onboardingSlice.actions
export default onboardingSlice.reducer
