import React, {memo} from 'react'
import { Steps } from 'antd'

interface IProps {
  currentStep: number,
  items: number[]
}

const StepsIndicator = ({currentStep, items}: IProps) => {
  return (
    <Steps
      type={'default'}
      current={currentStep}
      responsive={false}
      progressDot={(_iconDot, { status }) => {
        if (status === 'wait') {
          return <span className="wait-line"></span>
        }
        if (status === 'process' || status === 'finish') {
          return <span className="progress-line"></span>
        }
      }}
      items={items.map((step) => ({ key: step, onClick: () => undefined }))}
    />
  )
}

export default memo(StepsIndicator)
