import { LocalKey } from 'ts-localstorage'

export const API_URL = process.env.REACT_APP_API_URL

export const WIDGET_URL =
  API_URL === 'https://api.ioni.ai' ? 'widget.ioni.ai' : 'staging.widget.ioni.ai'
export const CHAT_URL =
  API_URL === 'https://api.ioni.ai' ? 'app.ioni.ai' : 'staging.ioni.ai'

export const DATA_HOST =
  API_URL !== 'https://api.ioni.ai' ? 'https://staging.widget.ioni.ai' : ''

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const HOME_ROUTE = '/'
export const KNOWLEDGE_BASE_ROUTE = '/storage'
export const AI_AGENT_ROUTE = '/agent'
export const CONVERSATIONS_ROUTE = '/conversations'
export const ACCOUNTS_ROUTE = '/accounts'
export const ONBOARDING_ROUTE = '/onboarding'
export const PROFILE_ROUTE = '/profile'
export const PROFILE_EDIT_ROUTE = '/profile/edit'
export const TEAM_ROUTE = '/team'
export const SIGN_IN_ROUTE = '/signin'
export const SIGN_UP_ROUTE = '/signup'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'

export const ACCESS_TOKEN = new LocalKey('accessToken', '')
export const REFRESH_TOKEN = new LocalKey('refreshToken', '')
export const ADMIN_USER_ID = new LocalKey('adminUserId', '')
export const ADMIN_ACCOUNT_ID = new LocalKey('adminAccountId', '')
