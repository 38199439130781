import React from 'react'
import { Tabs, TabsProps } from 'antd'
import ReviewAllDataTab from './components/tabs/ReviewAllDataTab/ReviewAllDataTab'
import AddNewDataTab from './components/tabs/AddNewDataTab/AddNewDataTab'
import SearchAndEditTab from './components/tabs/SearchAndEditTab/SearchAndEditTab'
import SettingsTab from './components/tabs/SettingsTab/SettingsTab'
import { useAppSelector } from '../../hooks/appHook'
import KnowledgeBasePageHeader from './components/KnowledgeBasePageHeader/KnowledgeBasePageHeader'
import './knowledgeBase.less'

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Add New Data',
    children: <AddNewDataTab />,
  },
  {
    key: '2',
    label: 'Search & Edit Data',
    children: <SearchAndEditTab />,
  },
  {
    key: '3',
    label: 'Review All Data',
    children: <ReviewAllDataTab />,
  },
  {
    key: '4',
    label: 'Settings',
    children: <SettingsTab />,
  },
]

const KnowledgeBase = () => {
  /** Storage */
  const { storages } = useAppSelector((state) => state.storage)

  return (
    <>
      <KnowledgeBasePageHeader title='Knowledge Base' />
      <div className="container">
        {storages.length ?
          <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane /> :
          <div className="empty-storages-container">
            To continue working, it is necessary to have at least one created storage. To create a storage,
            please click the “Create new storage” button.
          </div>}
      </div>
    </>
  )
}

export default KnowledgeBase
