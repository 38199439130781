import React, { memo } from 'react'
import { Message } from '../../../../types/storage.type'
import { ConversationContentItem } from '../../../../types/conversation.type'

interface IProps {
  message: Message,
}

const CustomMessage = ({ message }: IProps) => {
  let messageContent = null

  if (typeof message.content === 'string') {
    messageContent = message.content
  }

  if (Array.isArray(message.content)) {
    const messageText = message.content.find((el: ConversationContentItem) => el.type === 'text')

    const images = message.content.filter((el: ConversationContentItem) => el.type === 'image_url')

    messageContent = <div id={'paragraph-' + message.role} className={`${images.length > 0 ? 'paragraph-images' : 'paragraph'}`}>
      <div className="chat-message-images-preview">
        {images?.length ? images.map((img) => {
          return <div key={img.image_url?.url} className="image-preview">
            <img
              src={img.image_url?.url} alt="preview}"
              style={{ width: 'auto', height: 'auto', maxHeight: '60px' }}
              className="added-image-preview"
            />
          </div>
        }): null}
      </div>
      {messageText?.text ? <div>{messageText.text}</div> : null}
    </div>
  }

  return <div>{messageContent}</div>
}

export default memo(CustomMessage)
