import React, {memo } from 'react'
import './aiHelperHeader.less'

interface Props {
  title: string;
  paragraph?: string;
}

const defaultParagraph =
  `Check answers and adjust your Al Assistant. Add questions from your support requests, scheck the answer and edit 
  if needed. ioni will learn and automatically improve auto-replies.`

const AiHelperHeader = ({ title, paragraph = defaultParagraph }: Props) => {
  return (
    <div className="ai-helper-header">
      <h1>{title}</h1>
      {paragraph && <p>{paragraph}</p>}
    </div>
  )
}

export default memo(AiHelperHeader)
