import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Popconfirm, Popover } from 'antd'
import { useAppSelector } from '../../../../../hooks/appHook'
import { useErrorMessage } from '../../../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../../../hooks/useSuccessMessage'
import {
  useDeleteStorageMutation,
  useLazyGetStorageByIdQuery,
  useUpdateStorageMutation,
} from '../../../../../store/api/storage.api'
import { EditStorageRequest } from '../../../../../types/storage.type'
import TestChatButton from '../../TestChatButton/TestChatButton'
import EditStorageForm from '../../forms/EditStorageForm/EditStorageForm'
import TransparentButton from '../../../../../components/UI/TransparentButton/TransparentButton'
import './settingsTab.less';

const SettingsTab = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [updateStorageForm] = Form.useForm()

  /** Storage */
  const { currentStorageId, storages } = useAppSelector((state) => state.storage)

  /** Storage Actions */
  const [getStorage, getStorageQueryResult] = useLazyGetStorageByIdQuery()

  const [updateStorage, updateStorageMutationResult] = useUpdateStorageMutation()

  const [deleteStorage, deleteStorageMutationResult] = useDeleteStorageMutation()

  /** State */
  const [language, setLanguage] = useState<string>('English')
  const [isVisibleDeletePopover, setIsVisibleDeletePopover] = useState<boolean>(false)

  /** Response message handlers */
  useErrorMessage('Something went wrong', updateStorageMutationResult.error)
  useErrorMessage('Something went wrong', deleteStorageMutationResult.error)
  useSuccessMessage('Storage was successfully updated.', updateStorageMutationResult.isSuccess)
  useSuccessMessage('Storage was successfully deleted.', deleteStorageMutationResult.isSuccess)

  const currentStorage = useMemo(() => {
    return storages?.find((storage) => storage.id === currentStorageId) || null
  }, [currentStorageId, storages])

  useEffect(() => {
    getStorage({ id: currentStorageId })
  }, [currentStorageId])

  useEffect(() => {
    if (getStorageQueryResult.currentData && !getStorageQueryResult.isError) {
      const { name, distanceThreshold, resultsQuantity, language } = getStorageQueryResult.currentData

      updateStorageForm.setFieldsValue({ name, distanceThreshold, resultsQuantity })
      setLanguage(language)
    }
  }, [getStorageQueryResult])

  useEffect(() => {
    if (deleteStorageMutationResult.isSuccess) {
      const queryParams = new URLSearchParams(location.search)
      const queryStorageId = queryParams.get('storageId')

      if (queryStorageId && currentStorageId && currentStorageId !== queryStorageId) {
        queryParams.set('storageId', currentStorageId)
      }
      if (queryStorageId && !currentStorageId) {
        queryParams.delete('storageId')
      }
      /** Update the URL with the new query parameter, replacing the current entry without adding a new one to the history */
      navigate({ search: queryParams.toString() }, { replace: true })
    }
  }, [deleteStorageMutationResult, currentStorageId])

  const onCompleteFormCreateStorage = useCallback(
    (values: Partial<Storage>) => {
      updateStorage({ id: currentStorageId, body: { ...values, language } } as EditStorageRequest)
    },
    [language],
  )

  const onSaveConfigs = useCallback(() => {
    updateStorageForm.submit()
  }, [])

  const onDeleteStorage = useCallback(() => {
    deleteStorage(currentStorageId)
  }, [currentStorageId])

  const onSelectLanguage = useCallback((lang: string) => {
    setLanguage(lang)
  }, [])

  const WarningDeleteAgentPopoverContent = (
    <div>At least one storage is required. Please create different storage before removing this one.</div>
  )

  return (
    <div className="language-settings-tab">
      <div className="test-chat-button-container">
        <TestChatButton />
      </div>
      <div className="storage-form-container">
        <div className="tab-header">
          <h2>Settings</h2>
        </div>
        <EditStorageForm
          storageForm={updateStorageForm}
          language={language}
          setLanguage={onSelectLanguage}
          onSubmitForm={onCompleteFormCreateStorage}
        />
        <div className="storage-form-buttons">
          <Popover content={WarningDeleteAgentPopoverContent} open={isVisibleDeletePopover}>
            <Popconfirm
              icon={null}
              title="Delete storage"
              description={`Are you sure you want to remove storage
              ${currentStorage?.name}  with all data? This action is not reversible.`}
              onConfirm={onDeleteStorage}
              okText="Delete"
              cancelText="Cancel"
            >
              <div
                onMouseEnter={() => {
                  if (storages.length === 1) {
                    setIsVisibleDeletePopover(true)
                  }
                }}
                onMouseLeave={() => {
                  setIsVisibleDeletePopover(false)
                }}
              >
                <TransparentButton
                  disabled={storages.length === 1}
                  className="transparent-button-small delete-storage-button"
                  title="Delete storage"
                />
              </div>
            </Popconfirm>
          </Popover>
          <TransparentButton
            className="transparent-button-small create-storage-button"
            title="Save changes"
            onClick={onSaveConfigs}
          />
        </div>
      </div>
    </div>
  )
}

export default SettingsTab
